export const productData = [
  {
    id: 1,
    name: "asanoha",
    src: "https://wasei-golf-korea.com/images/asanoha.jpg",
    img: 4,
  },
  {
    id: 2,
    name: "sakura",
    src: "https://wasei-golf-korea.com/images/sakura.jpg",
    img: 2,
  },
];
