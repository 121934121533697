import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import TopTitle from "./TopTitle";
import { companyData } from "../data/company";
import {
  companyDataBoxStyle,
  containerStyle,
  contentsFontStyle,
  fontBoxStyle,
  subContainerStyle,
  titleFontStyle,
  topTitleStyle,
} from "../style/companyStyle";
import SEOMetaTag from "../SEOMetaTag";

function Company({ setClick }) {
  const theme = createTheme({
    breakpoints: {
      values: {
        xxxs: 0,
        xxs: 300,
        xxss: 390,
        xs: 400,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1800,
        xxl: 2400,
      },
    },
  });

  useEffect(() => {
    setClick(true);
  }, []);
  return (
    <div className="company-page">
      <SEOMetaTag
        title="Company | WASEI golf"
        description="WASEI Produced by Ikuo Suzuki"
        keywords="WASEI, WASEI GOLF, WASEI KOREA, WASEI 아이언, WASEI 골프, 와세이, 와세이 골프, 아이언, 와세이 코리아"
        imgsrc="https://wasei-golf-korea.com/images/asanoha.jpg"
        url="https://wasei-golf-korea.com/company"
      />
      <ThemeProvider theme={theme}>
        <Box sx={containerStyle}>
          <Box sx={subContainerStyle}>
            <TopTitle title={topTitleStyle.title} width={topTitleStyle.width} />
            <Box sx={companyDataBoxStyle}>
              {companyData.map((arr) => (
                <Box key={arr[0]} display="flex" sx={fontBoxStyle}>
                  <Typography sx={titleFontStyle}>{arr[0]}</Typography>
                  <Typography sx={contentsFontStyle}>{arr[1]}</Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </ThemeProvider>
    </div>
  );
}

export default Company;
