import React, { useEffect } from "react";
import { Box, Divider, Typography } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { shopListData } from "../data/shopList";
import TopTitle from "./TopTitle";
import {
  containerStyle,
  dividerStyle,
  fontStyle,
  shopListDataBoxStyle,
  subContainerStyle,
  topTitleStyle,
} from "../style/shopListStyle";
import SEOMetaTag from "../SEOMetaTag";

function ShopList({ setClick }) {
  const theme = createTheme({
    breakpoints: {
      values: {
        xxxs: 0,
        xxs: 300,
        xxss: 390,
        xs: 400,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1800,
        xxl: 2400,
      },
    },
  });

  useEffect(() => {
    setClick(true);
  }, []);
  return (
    <div className="shop-list-page">
      <SEOMetaTag
        title="ShopList | WASEI golf"
        description="WASEI Produced by Ikuo Suzuki"
        keywords="WASEI, WASEI GOLF, WASEI KOREA, WASEI 아이언, WASEI 골프, 와세이, 와세이 골프, 아이언, 와세이 코리아"
        imgsrc="https://wasei-golf-korea.com/images/asanoha.jpg"
        url="https://wasei-golf-korea.com/shopList"
      />
      <ThemeProvider theme={theme}>
        <Box sx={containerStyle}>
          <Box sx={subContainerStyle}>
            <TopTitle title={topTitleStyle.title} width={topTitleStyle.width} />
            <Box sx={shopListDataBoxStyle}>
              {shopListData.map((data) => (
                <Typography key={data.id} sx={fontStyle}>
                  {data.name}
                </Typography>
              ))}
            </Box>
            <Divider sx={dividerStyle} />
          </Box>
        </Box>
      </ThemeProvider>
    </div>
  );
}

export default ShopList;
