import React, { useEffect } from "react";
import { Box, Divider, Grid, Typography } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import ProductTitle from "./ProductTitle";
import { productData } from "../data/product";
import { Link } from "react-router-dom";
import {
  containerStyle,
  gridContainerStyle,
  nameDividerStyle,
  nameFontStyle,
  productImageStyle,
  titleContainerStyle,
  titleStyle,
} from "../style/productStyle";
import SEOMetaTag from "../SEOMetaTag";

function Product({ setClick }) {
  const theme = createTheme({
    breakpoints: {
      values: {
        xxxs: 0,
        xxs: 300,
        xxss: 390,
        xs: 400,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1800,
        xxl: 2400,
      },
    },
  });

  useEffect(() => {
    setClick(true);
  }, []);
  return (
    <div className="product-page">
      <SEOMetaTag
        title="Product | WASEI golf"
        description="WASEI Produced by Ikuo Suzuki"
        keywords="WASEI, WASEI GOLF, WASEI KOREA, WASEI 아이언, WASEI 골프, 와세이, 와세이 골프, 아이언, 와세이 코리아"
        imgsrc="https://wasei-golf-korea.com/images/asanoha.jpg"
        url="https://wasei-golf-korea.com/product"
      />
      <ThemeProvider theme={theme}>
        <Box sx={containerStyle}>
          <Box sx={titleContainerStyle}>
            <Box sx={titleStyle}>
              <ProductTitle />
            </Box>
          </Box>
          <Grid
            container
            spacing={{ xxxs: 2, xxs: 4, sm: 4, md: 2, lg: 6, xl: 6 }}
            columns={{ xxxs: 6, sm: 12, md: 12, lg: 12, xl: 12 }}
            sx={gridContainerStyle}
          >
            {productData.map((data) => (
              <Grid item xxxs={6} sm={12} md={12} lg={6} xl={6} key={data.name}>
                <Link to={`/product/${data.name}/${data.img}`}>
                  <Box
                    component="img"
                    sx={productImageStyle}
                    src={`${data.src}`}
                    alt={data.id}
                  />
                </Link>
                <Box display="flex">
                  <Divider sx={nameDividerStyle} />
                  <Typography sx={nameFontStyle}>{data.name}</Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </ThemeProvider>
    </div>
  );
}

export default Product;
