import React from "react";
import PropTypes from "prop-types";
import { Box, Divider, Typography } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { titleFontStyle, dividerStyle } from "../style/topTitleStyle";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function TopTitle({ title, width }) {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const theme = createTheme({
    breakpoints: {
      values: {
        xxxs: 0,
        xxs: 300,
        xs: 400,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1800,
        xxl: 2400,
      },
    },
  });

  return (
    <Box
      display="flex"
      width="100%"
      justifyContent="space-between"
      className="news-title"
    >
      <Box data-aos="fade-right" data-aos-easing="ease-in-sine">
        <Typography sx={titleFontStyle}>{title}</Typography>
      </Box>
      <Box
        data-aos="fade-right"
        data-aos-easing="ease-in-sine"
        data-aos-delay="500"
        display="flex"
        alignItems="center"
      >
        <Divider
          textAlign="right"
          sx={{
            ...dividerStyle,
            width: width,
          }}
        />
      </Box>
    </Box>
  );
}

export default TopTitle;
