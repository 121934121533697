export const containerStyle = {
  paddingLeft: { xxxs: "7.5%", lg: "2.5%" },
  paddingRight: { xxxs: "5.5%", lg: "2.5%" },
};

export const subContainerStyle = {
  flexGrow: 1,
  display: { xxxs: "flex", lg: "flex" },
  flexDirection: { xxxs: "column", lg: "row-reverse" },
  justifyContent: "left",
  alignItems: { lg: "flex-end" },
};

export const logoBoxStyle = {
  flexGrow: 1,
  display: { xxxs: "flex", lg: "flex" },
  marginBottom: { xxxs: "18px", lg: "5px" },
};

export const logoImageStyle = {
  // width: "80%",
  // height: "65%",

  // height: {
  //   xxxs: "25px",
  //   xxs: "30px",
  //   xs: "30px",
  //   sm: "40px",
  //   md: "45px",
  // },
  width: {
    xxxs: "80px",
    xxs: "100px",
    xs: "110px",
    sm: "140px",
    md: "140px",
  },
};

export const mainFooterTitleFontStyle = {
  color: "#fff",
  fontSize: {
    xxxs: "22px",
    sm: "30px",
    md: "36px",
    lg: "46px",
    xl: "54px",
    xxl: "56px",
  },
};

export const mainFooterSubtitleFontStyle = {
  color: "#fff",
  fontSize: {
    xxsxxxs: "12px",
    sm: "18px",
    lg: "20px",
    xl: "22px",
    xxl: "24px",
  },
  lineHeight: { xxxs: 0.6, sm: 0.5, md: 0.7, lg: 0.5 },
};

export const mobileFooterAddressFontStyle = {
  display: { md: "none" },
  marginBottom: "10px",
  lineHeight: {
    xxxs: 2,
  },
};
