import React, { useEffect } from "react";
import Slider from "react-slick";
import { Box } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import {
  settings,
  slideFirstImageStyle,
  slideFirstMobileImageStyle,
  slideImageStyle,
} from "../style/homeStyle";
import SEOMetaTag from "../SEOMetaTag";

function Home({ setClick }) {
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1800,
        xxl: 2400,
      },
    },
  });

  useEffect(() => {
    setClick(false);
  }, []);

  return (
    <div className="first-section">
      <SEOMetaTag
        title="WASEI golf"
        description="WASEI Produced by Ikuo Suzuki"
        keywords="WASEI, WASEI GOLF, WASEI KOREA, WASEI 아이언, WASEI 골프, 와세이, 와세이 골프, 아이언, 와세이 코리아"
        imgsrc="https://wasei-golf-korea.com/images/asanoha.jpg"
        url="https://wasei-golf-korea.com/"
      />
      <ThemeProvider theme={theme}>
        <Slider {...settings}>
          <Box>
            <Box
              component="img"
              sx={slideFirstImageStyle}
              src="images/bg_8.jpg"
              alt="FITTING LINE"
              fetchpriority="high"
            />
            <Box
              component="img"
              sx={slideFirstMobileImageStyle}
              src="images/bg_4.jpg"
              alt="FITTING LINE"
              fetchpriority="high"
            />
          </Box>
          <Box
            component="img"
            sx={slideImageStyle}
            src="images/bg_6.jpg"
            alt="FITTING LINE"
            fetchpriority="high"
          />
          <Box
            component="img"
            sx={slideImageStyle}
            src="images/bg_7.jpg"
            alt="FITTING LINE"
            fetchpriority="high"
          />
          {/* <Box
            component="img"
            sx={slideImageStyle}
            src="images/bg_5.jpg"
            alt="FITTING LINE"
            fetchpriority="high"
          /> */}
        </Slider>
      </ThemeProvider>
    </div>
  );
}

export default Home;
