export const containerStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "flex-start",
  minHeight: {
    xxxs: "72vh",
    xxs: "72vh",
    xxss: "70vh",
    xs: "75vh",
    sm: "69.2vh",
    md: "1050px",
    lg: "750px",
    xl: "70vh",
    xxl: "72vh",
  },
  paddingTop: {
    xxxs: "90px",
    xxs: "90px",
    xxss: "120px",
    xs: "100px",
    sm: "200px",
    md: "190px",
    lg: "200px",
    xl: "250px",
    xxl: "300px",
  },
  overflowY: "scroll",
};

export const subContainerStyle = {
  width: {
    xxxs: "240px",
    xxs: "320px",
    xs: "360px",
    sm: "680px",
    md: "800px",
    lg: "700px",
    xxl: "760px",
  },
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",
};

export const topTitleStyle = {
  title: "Company",
  width: {
    xxxs: "130px",
    xxs: "200px",
    xs: "240px",
    sm: "520px",
    md: "630px",
    lg: "540px",
    xxl: "590px",
  },
};

export const companyDataBoxStyle = {
  marginTop: {
    xxxs: "40px",
    xs: "60px",
    sm: "60px",
    md: "70px",
    xl: "70px",
  },
  width: "100%",
};

export const fontBoxStyle = {
  marginBottom: {
    xxs: "10px",
    sm: "30px",
    xl: "35px",
  },
};

export const titleFontStyle = {
  width: {
    xxxs: "135px",
    xxs: "120px",
    xs: "120px",
    sm: "160px",
    md: "180px",
    lg: "160px",
    xl: "160px",
    xxl: "170px",
  },
  textAlign: "left",
  fontSize: {
    xxxs: "12px",
    xs: "14px",
    sm: "16px",
    md: "20px",
    lg: "14px",
    xxl: "16px",
  },
  lineHeight: {
    xxxs: 2.5,
  },
};

export const contentsFontStyle = {
  width: {
    xxxs: "180px",
    xxs: "200px",
    xs: "230px",
    sm: "500px",
    md: "570px",
    lg: "550px",
    xl: "450px",
    xxl: "530px",
  },
  textAlign: "left",
  fontSize: {
    xxxs: "12px",
    xs: "14px",
    sm: "16px",
    md: "20px",
    lg: "14px",
    xxl: "16px",
  },
  lineHeight: {
    xxxs: 2.5,
  },
};
