import React, { useEffect } from "react";
import Slider from "react-slick";
import { Box, Divider, Grid } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import {
  containerStyle,
  contentsBottomDividerStyle,
  contentsContainerStyle,
  contentsImageBoxStyle,
  contentsImageStyle,
  contentsTopDividerStyle,
  gridStyle,
  settings,
  sliderStyle,
} from "../style/productLayoutStyle";
import SEOMetaTag from "../SEOMetaTag";

function ProductPageLayout({ setClick }) {
  let product = useParams();
  const productNumber = Number(product.number);
  const arr = [];
  for (let i = 0; i < productNumber; i++) {
    arr.push(i);
  }

  const theme = createTheme({
    breakpoints: {
      values: {
        xxxs: 0,
        xxs: 300,
        xxss: 390,
        xs: 400,
        sm: 600,
        md: 900,
        mdd: 1000,
        lg: 1200,
        xl: 1800,
        xxl: 2400,
      },
    },
  });

  useEffect(() => {
    setClick(true);
  }, []);
  return (
    <div className="product-page">
      <SEOMetaTag
        title={`${product.id} | WASEI golf`}
        description="WASEI Produced by Ikuo Suzuki"
        keywords="WASEI, WASEI GOLF, WASEI KOREA, WASEI 아이언, WASEI 골프, 와세이, 와세이 골프, 아이언, 와세이 코리아"
        imgsrc="https://wasei-golf-korea.com/images/asanoha.jpg"
        url="https://wasei-golf-korea.com/product"
      />
      <ThemeProvider theme={theme}>
        <Grid
          container
          columns={{ xxxs: 4, xxs: 4, sm: 12, md: 12, lg: 12, xl: 12, xxl: 12 }}
          sx={containerStyle}
          justifyContent={"center"}
          alignContent={"center"}
        >
          <Grid
            item
            xxxs={3.5}
            xxs={3.5}
            sm={10}
            md={10}
            mdd={9.5}
            lg={4}
            xl={3.3}
            xxl={2.7}
            sx={gridStyle}
          >
            <Slider {...settings}>
              {arr.map((img, idx) => {
                return (
                  <Box
                    key={product.id}
                    component="img"
                    sx={sliderStyle}
                    src={`https://wasei-golf-korea.com/images/${product.id}/${
                      idx + 1
                    }.jpg`}
                    alt="wasei golf"
                  />
                );
              })}
            </Slider>
          </Grid>
          <Grid
            item
            xxxs={3.5}
            xxs={3.5}
            sm={10}
            md={10}
            lg={4}
            xl={3}
            xxl={2.8}
            sx={contentsContainerStyle}
          >
            <Divider sx={contentsTopDividerStyle} />
            <Box sx={contentsImageBoxStyle}>
              <Box
                component="img"
                sx={contentsImageStyle}
                src={`https://wasei-golf-korea.com/images/${product.id}/${product.id}.jpg`}
                alt="wasei golf"
              />
            </Box>

            <Divider sx={contentsBottomDividerStyle} />
          </Grid>
        </Grid>
      </ThemeProvider>
    </div>
  );
}

export default ProductPageLayout;
