import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { Divider, Menu, ThemeProvider, createTheme } from "@mui/material";
import { Link } from "react-router-dom";
import {
  containerStyle,
  dividerStyle,
  left,
  list,
  mobileFontStyle,
  right,
  subContainerStyle,
  iconStyle,
  menuAppbarStyle,
  menuItemStyle,
  linkStyle,
  logoImageStyleStyle,
  pcListBoxStyle,
  pcListButtonStyle,
  pcHeaderTopBoxStyle,
  pcHeaderTopFontStyle,
  appbarContainerStyle,
} from "../style/headerStyle";

function Header({ click }) {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [checked, setChecked] = React.useState(false);

  const clickColor = click || anchorElNav !== null ? "#000" : "#fff";

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
    setChecked((prev) => !prev);
    anchorElNav !== null && setAnchorElNav(null);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const theme = createTheme({
    breakpoints: {
      values: {
        xxs: 0,
        xs: 300,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1800,
        xxl: 2400,
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <AppBar
        className="header"
        color="transparent"
        elevation={0}
        sx={appbarContainerStyle}
      >
        <Container maxWidth="1000px" sx={containerStyle}>
          <Toolbar disableGutters>
            <Box sx={subContainerStyle}>
              <IconButton
                size="large"
                className="icon-button"
                onClick={handleOpenNavMenu}
              >
                <MenuIcon
                  sx={{
                    color: clickColor,
                    ...iconStyle,
                  }}
                />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={() => setAnchorElNav(null)}
                sx={menuAppbarStyle}
              >
                {left.map((page) => (
                  <MenuItem
                    key={page.name}
                    onClick={handleCloseNavMenu}
                    className="appbar"
                    sx={menuItemStyle}
                  >
                    <Typography textAlign="center" sx={mobileFontStyle}>
                      <Link to={page.link} style={linkStyle}>
                        {page.name}
                      </Link>
                    </Typography>
                    <Divider textAlign="right" sx={dividerStyle} />
                  </MenuItem>
                ))}
                {right.map((page) => (
                  <MenuItem
                    key={page.name}
                    onClick={handleCloseNavMenu}
                    className="appbar"
                    sx={menuItemStyle}
                  >
                    <Typography textAlign="center" sx={mobileFontStyle}>
                      <Link to={page.link} style={linkStyle}>
                        {page.name}
                      </Link>
                    </Typography>
                    <Divider textAlign="right" sx={dividerStyle} />
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            {click && (
              <Link to="/">
                <Box
                  component="img"
                  src="https://wasei-golf-korea.com/images/logo6.png"
                  sx={logoImageStyleStyle}
                />
              </Link>
            )}
            <Box sx={pcListBoxStyle}>
              <Box display="flex" justifyContent="space-between">
                {list.map((page) => (
                  <Button key={page.name} sx={pcListButtonStyle}>
                    <Link to={page.link} style={{ color: clickColor }}>
                      {page.name}
                    </Link>
                  </Button>
                ))}
              </Box>
            </Box>
            {click && (
              <Box sx={pcHeaderTopBoxStyle}>
                <Typography sx={pcHeaderTopFontStyle}>
                  WASEI GOLF KOREA
                </Typography>
                <Typography sx={pcHeaderTopFontStyle}>070-4286-1170</Typography>
                <Typography sx={pcHeaderTopFontStyle}>
                  am 9:00 – pm 6:00
                </Typography>
              </Box>
            )}
          </Toolbar>
        </Container>
      </AppBar>
    </ThemeProvider>
  );
}
export default Header;
