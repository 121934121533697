import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { ThemeProvider, createTheme } from "@mui/material";
import { Link } from "react-router-dom";
import {
  containerStyle,
  logoBoxStyle,
  logoImageStyle,
  mainFooterSubtitleFontStyle,
  mainFooterTitleFontStyle,
  subContainerStyle,
  mobileFooterAddressFontStyle,
} from "../style/footerStyle";

function Footer({ click }) {
  const theme = createTheme({
    breakpoints: {
      values: {
        xxxs: 0,
        xxs: 300,
        xs: 400,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1800,
        xxl: 2400,
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Box
        className="footer"
        width="100vw"
        position={click ? "relative" : "fixed"}
        bottom="3%"
        paddingBottom={click && "20px"}
      >
        <Container maxWidth="1600px" sx={containerStyle}>
          <Box sx={subContainerStyle}>
            <Box
              sx={{
                ...logoBoxStyle,
                justifyContent: { xxxs: click ? "left" : "right", lg: "right" },
              }}
            >
              {click ? (
                <Link to="/">
                  <Box
                    component="img"
                    sx={logoImageStyle}
                    src="https://wasei-golf-korea.com/images/foot_logo.png"
                    alt="FITTING LINE"
                    fetchpriority="high"
                  />
                </Link>
              ) : (
                <Box textAlign="right">
                  <Typography sx={mainFooterTitleFontStyle}>
                    Japanes Heritage
                  </Typography>
                  <Typography sx={mainFooterSubtitleFontStyle}>
                    Sashikan Tategu Kogei
                  </Typography>
                </Box>
              )}
            </Box>
            <Box textAlign="left">
              <Typography
                sx={{
                  ...mobileFooterAddressFontStyle,
                  color: click ? "#000" : "#fff",
                  fontSize: {
                    xxxs: click ? "8px" : "0px",
                    xxs: click ? "9px" : "0px",
                    xs: click ? "11px" : "0px",
                    sm: click ? "10px" : "0px",
                    md: click ? "14px" : "0px",
                    lg: "12px",
                    xl: "13px",
                    xxl: "16px",
                  },
                }}
              >
                주소 : 경기도 하남시 미사강변한강로 135, 제다동 제6층 644호
                <br />
                (망월동, 미사강변스카이폴리스지식산업센터)
              </Typography>
            </Box>
            <Box textAlign="left">
              <Typography
                sx={{
                  color: click ? "#000" : "#fff",
                  fontSize: {
                    xxxs: click ? "7px" : "0px",
                    xxs: click ? "8px" : "0px",
                    xs: click ? "9px" : "0px",
                    sm: click ? "10px" : "0px",
                    md: click ? "14px" : "0px",
                    lg: "12px",
                    xl: "13px",
                    xxl: "16px",
                  },
                }}
              >
                © WASEI GOLF KOREA INC. ALL RIGHTS RESERVED.
              </Typography>
            </Box>
          </Box>
        </Container>
      </Box>
    </ThemeProvider>
  );
}
export default Footer;
