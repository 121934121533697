export const companyData = [
  ["회사명", "WASEI GOLF KOREA"],
  ["대표자", "김위중"],
  [
    "위치",
    "경기도 하남시 미사강변한강로 135, 제다동 제6층 644호 (망월동, 미사강변스카이폴리스지식산업센터)",
  ],
  ["TEL", "070-4286-1170"],
  // ["MAIL", "wasei@wasei"],
  ["URL", "https://wasei-golf-korea.com"],
];
