import React, { useEffect } from "react";
import { Box, Divider, Typography } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import { allNewsData } from "../data/allNews";
import TopTitle from "./TopTitle";
import {
  containerStyle,
  contentsFontStyle,
  dividerStyle,
  newsTitleFontStyle,
  topTitleStyle,
} from "../style/newsDetailStyle";
import SEOMetaTag from "../SEOMetaTag";

function NewsDetail({ setClick }) {
  let product = useParams();
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const theme = createTheme({
    breakpoints: {
      values: {
        xxxs: 0,
        xxs: 300,
        xs: 400,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1800,
        xxl: 2400,
      },
    },
  });

  useEffect(() => {
    setClick(true);
  }, []);

  return (
    <div className="news-detail-page">
      <SEOMetaTag
        title="News | WASEI golf"
        description="WASEI Produced by Ikuo Suzuki"
        keywords="WASEI, WASEI GOLF, WASEI KOREA, WASEI 아이언, WASEI 골프, 와세이, 와세이 골프, 아이언, 와세이 코리아"
        imgsrc="https://wasei-golf-korea.com/images/asanoha.jpg"
        url="https://wasei-golf-korea.com/"
      />
      <ThemeProvider theme={theme}>
        <Box sx={containerStyle}>
          <Box>
            <TopTitle title={topTitleStyle.title} width={topTitleStyle.width} />
            <Typography sx={newsTitleFontStyle}>
              {allNewsData[product.id - 1].name}
            </Typography>
            <Divider sx={dividerStyle} />
            <Typography sx={contentsFontStyle}>
              {allNewsData[product.id - 1].contents}
            </Typography>
          </Box>
        </Box>
      </ThemeProvider>
    </div>
  );
}

export default NewsDetail;
