import Header from "./component/Header";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./App.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect, useState } from "react";
import Footer from "./component/Footer";
import Roading from "./component/Roading";
import { Route, Routes } from "react-router-dom";
import Home from "./component/Home";
import News from "./component/News";
import Product from "./component/Product";
import ShopList from "./component/ShopList";
import Company from "./component/Company";
import NewsDetail from "./component/NewsDetail";
import ProductPageLayout from "./component/ProductPageLayout";
import SEOMetaTag from "./SEOMetaTag";

function App() {
  const [roading, setRoading] = useState(false);
  const handleRoading = () => setTimeout(() => setRoading(false), 4300);
  const [click, setClick] = useState(false);

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
    setRoading(true);
    handleRoading();
  }, [clearTimeout(handleRoading)]);

  return (
    <div className="App">
      <SEOMetaTag
        title="WASEI golf"
        description="WASEI Produced by Ikuo Suzuki"
        keywords="WASEI, WASEI GOLF, WASEI KOREA, WASEI 아이언, WASEI 골프, 와세이, 와세이 골프, 아이언, 와세이 코리아"
        imgsrc="https://wasei-golf-korea.com/images/asanoha.jpg"
        url="https://wasei-golf-korea.com/"
      />
      {roading ? (
        <Roading />
      ) : (
        <>
          <Header click={click} />
          <Routes>
            <Route path="/" element={<Home setClick={setClick} />} />
            {/* <Route path="/news" element={<News setClick={setClick} />} /> */}
            <Route
              path="/news/:id"
              element={<NewsDetail setClick={setClick} />}
            />
            <Route path="/product" element={<Product setClick={setClick} />} />
            <Route
              path="/product/:id/:number"
              element={<ProductPageLayout setClick={setClick} />}
            />
            <Route
              path="/shopList"
              element={<ShopList setClick={setClick} />}
            />
            <Route path="/company" element={<Company setClick={setClick} />} />
          </Routes>
          <Footer click={click} />
        </>
      )}
    </div>
  );
}

export default App;
