export const shopListData = [
  {
    id: 1,
    name: "평택 골프채 연구소",
  },
  {
    id: 2,
    name: "서울 보스골프",
  },
  {
    id: 3,
    name: "서울 리치골프",
  },
  {
    id: 4,
    name: "서울 스타트골프",
  },
  {
    id: 5,
    name: "서울 골프플래닛",
  },
  {
    id: 6,
    name: "판교 에이드골프",
  },
  {
    id: 7,
    name: "일산 TNT 골프",
  },
  {
    id: 8,
    name: "일산 피팅존",
  },
  {
    id: 9,
    name: "성남 노바인터네셔널",
  },
  {
    id: 10,
    name: "동탄 크레이지골프",
  },
  {
    id: 11,
    name: "충북 더원골프",
  },
  {
    id: 12,
    name: "청주 골프닥터",
  },
  {
    id: 13,
    name: "여수 골프백화점",
  },
  {
    id: 14,
    name: "진주 프리골프",
  },
  {
    id: 15,
    name: "창원 원골프",
  },
  {
    id: 16,
    name: "부산 원골프",
  },
  {
    id: 17,
    name: "울산 프리골프",
  },
  {
    id: 18,
    name: "포항 탑골프",
  },
  {
    id: 19,
    name: "대구 펜타골프",
  },
  {
    id: 20,
    name: "서울 기어스골프",
  },
  {
    id: 21,
    name: "광주 TGF 골프",
  },
];
