export const settings = {
  arrows: false,
  dots: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 5000,
  pauseOnHover: false,
  dotsClass: "slick-dots",
};

export const containerStyle = {
  paddingTop: {
    sm: "40px",
    md: "40px",
    mdd: "20px",
    lg: "40px",
    xl: "60px",
    xxl: "30px",
  },
  height: {
    xxxs: "600px",
    xxs: "780px",
    xxss: "96vh",
    xs: "96vh",
    sm: "1500px",
    md: "1750px",
    mdd: "2000px",
    lg: "95vh",
    xl: "95vh",
    xxl: "95vh",
  },
};

export const gridStyle = {
  marginTop: { lg: "13px", xl: "15px" },
};

export const sliderStyle = {
  objectFit: "cover",
  maxHeight: {
    lg: "382px",
    xl: "505px",
    xxl: "540px",
  },
};

export const contentsContainerStyle = {
  marginLeft: {
    lg: "55px",
    xl: "70px",
  },
};

export const contentsTopDividerStyle = {
  marginTop: {
    xxxs: "10px",
    xxs: "20px",
    xs: "14px",
    sm: "30px",
    lg: "15px",
  },
  width: "100%",
  borderColor: "#000",
};

export const contentsImageBoxStyle = {
  width: "100%",
  maxHeight: {
    xxxs: "220px",
    xxs: "288px",
    xxss: "310px",
    xs: "330px",
    sm: "580px",
    md: "680px",
    mdd: "780px",
    lg: "380px",
    xl: "500px",
    xxl: "540px",
  },
  overflowY: "scroll",
  overflowX: "hidden",
};

export const contentsImageStyle = {
  width: "100%",
  objectFit: "cover",
};

export const contentsBottomDividerStyle = {
  width: "100%",
  borderColor: "#000",
};
