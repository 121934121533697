export const settings = {
  arrows: false,
  dots: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 5000,
  pauseOnHover: false,
  dotsClass: "slick-dots",
};

export const slideImageStyle = {
  width: "100vw",
  height: "100vh",
  objectFit: "cover",
};

export const slideFirstImageStyle = {
  height: { xs: "0px", sm: "0px", md: "100vh" },
  width: { xs: "0px", sm: "0px", md: "100vw" },
  objectFit: "cover",
};

export const slideFirstMobileImageStyle = {
  height: { xs: "100vh", sm: "100vh", md: "0px" },
  width: { xs: "100vw", sm: "100vw", md: "0px" },
  objectFit: "cover",
};
