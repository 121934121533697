export const allNewsData = [
  {
    id: 1,
    date: "2023.09.24",
    name: "WASEI KOREA가 판매 개시합니다.",
    contents: `2023년 9월 22일 WASEI KOREA가 판매 개시합니다. 철이 전하다. 일본의 제조의 힘을 세계에 전하고 싶다. 그런 생각에서 WASEI의 이야기는 시작되었다.`,
  },
  {
    id: 2,
    date: "2023.05.21",
    name: "WASEI 제2탄 모델 「sakura」 올가을 데뷔",
    contents:
      "사쿠라처럼 전설의 기술을 미래로 전하는 아이언의 완성형 왕벚나무는 전 세계에서 사랑받는 일본의 벚꽃. 그 원점은 한 그루의 벚꽃에 있고, 접목에 의해서, 그 DNA를 펼쳐, 지금에 전해 왔다고 한다. 사쿠라는 숙련된 연마사가 형상화한 아이언의 이상형을 바탕으로 풀밀드를 통해 100% 재현한 모델. 정말 왕벚꽃처럼, 하나의 DNA를 그대로 많은 플레이어에게 계속 전달한다.",
  },
  {
    id: 3,
    date: "2023.05.21",
    name: "WASEI 제1탄 모델 「asanoha」 데뷔",
    contents:
      "건물을 계속 지탱하는 조코의 기술로 강하고, 아름다운 아이언으로 예로부터 일본의 창호에 이용되어 온 「쿠미코」. 조립된 나무는 결코 흔들림 없이 건물을 지탱해 왔다. 섬세한 조목은 강하고 아름답다.asanoha는 조코의 대표적인 무늬 중 하나인 삼잎을 캐비티 백에 새긴 모델. 나무의 짜임새, 결까지 치밀하게 재현하였으며, 짜임새의 구조를 철로 표현하였다. 의장성을 높일 뿐만 아니라, 얇게 마감된 페이스를 강하게 지지해, 타감의 좋은 점을 낳는다. 삼잎은 마귀를 쫓거나 건강한 성장을 의미하는 모양이다. 플레이어의 기술 향상에의 생각도 담았다.",
  },
];
