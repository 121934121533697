export const left = [
  { name: "Home", link: "/" },
  // { name: "News", link: "/news" },
  { name: "Product", link: "/product" },
];
export const right = [
  { name: "ShopList", link: "/shopList" },
  { name: "Company", link: "/company" },
  { name: "SNS", link: "/" },
];
export const list = [
  // { name: "News", link: "/news" },
  { name: "Product", link: "/product" },
  { name: "ShopList", link: "/shopList" },
  { name: "Company", link: "/company" },
  { name: "SNS", link: "/" },
];

export const mobileFontStyle = {
  fontSize: {
    xxs: "16px",
    xs: "18px",
    sm: "26px",
    md: "28px",
  },
  marginLeft: {
    xxs: "10px",
    xs: "27px",
    sm: "35px",
    md: "60px",
  },
};

export const dividerStyle = {
  position: "absolute",
  borderBottomWidth: 0.7,
  borderColor: "#000",
  left: { xxs: "43%", xs: "39%", sm: "37%" },
  width: { xxs: "45%", xs: "48%", sm: "50%" },
};

export const appbarContainerStyle = {
  position: "fixed",
  top: { xxs: "0%", sm: "1%", lg: "3%" },
  zIndex: "2000",
};

export const containerStyle = {
  paddingLeft: { xxs: "0", lg: "3.3%", xl: "1.5%" },
  paddingRight: { lg: "3%", xl: "2%" },
};

export const subContainerStyle = {
  flexGrow: 1,
  display: {
    xxs: "flex",
    lg: "none",
  },
  flexDirection: "column",
};

export const iconStyle = {
  fontSize: { sm: "30px", md: "40px" },
  marginLeft: { sm: "15px", md: "10px" },
};

export const menuAppbarStyle = {
  display: { xs: "block", md: "none" },
};

export const menuItemStyle = {
  height: { xxs: "70px", sm: "100px", md: "120px" },
};

export const linkStyle = {
  textDecoration: "none",
  color: "#000",
  fontFamily: "EB Garamond, serif",
};

export const logoImageStyleStyle = {
  display: { xxs: "none", lg: "flex" },
  marginLeft: { md: "17%" },
  width: { md: "103px" },
};

export const pcListBoxStyle = {
  flexGrow: 1,
  display: { xxs: "none", lg: "flex" },
  justifyContent: "center",
};

export const pcListButtonStyle = {
  my: 2,
  display: "block",
  width: { lg: "150px", xl: "240px", xxl: "300px" },
  fontSize: { lg: "12px", xl: "14 px", xxl: "16px" },
  marginTop: "none",
};

export const pcHeaderTopBoxStyle = {
  display: { xxs: "none", sm: "flex" },
  justifyContent: "end",
  flexDirection: "column",
  marginRight: { sm: "1%", md: "1%", lg: "0%", xl: "0%" },
  marginTop: { sm: "20px", md: "30px", lg: "0px" },
  textAlign: "right",
};

export const pcHeaderTopFontStyle = {
  fontSize: { sm: "10px", md: "12px", lg: "10px" },
};
