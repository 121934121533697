export const containerStyle = {
  display: "flex",
  position: "relative",
  justifyContent: "center",
  alignItems: "center",
  width: "100vw",
  height: "100vh",
};

export const middleLogoStyle = {
  display: { xs: "none", lg: "flex" },
  width: "100%",
  height: "100%",
  maxHeight: { lg: "92%", xl: "88%" },
  objectFit: "cover",
};

export const bottomLogoStyle = {
  display: { xs: "none", lg: "flex" },
  maxHeight: { lg: "5%", xl: "6%", xxl: "5.5%" },
  bottom: { xs: "36%", lg: "2%" },
  right: { xs: "40%", lg: "2%" },
  objectFit: "cover",
};

export const mobileTopLogoStyle = {
  display: { xs: "flex", lg: "none" },
  maxHeight: { xs: "35%", md: "40%", lg: "92%", xl: "88%" },
  objectFit: "cover",
};

export const mobileBottomLogoStyle = {
  display: { xs: "flex", lg: "none" },
  maxHeight: { xs: "35%", md: "40%", lg: "92%", xl: "88%" },
  objectFit: "cover",
};

export const bgStyle = {
  backgroundColor: "#fff",
  width: "100%",
  height: "100%",
};
