export const containerStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  width: {
    xxxs: "240px",
    xxs: "320px",
    xs: "360px",
    sm: "680px",
    md: "800px",
    lg: "700px",
    xxl: "760px",
  },
  minHeight: {
    xxxs: "90vh",
    xxs: "71.9vh",
    xs: "75vh",
    sm: "69.2vh",
    md: "75.5vh",
    lg: "700px",
    xl: "70vh",
    xxl: "72vh",
  },
  paddingTop: {
    xxxs: "90px",
    xxs: "90px",
    xs: "100px",
    sm: "200px",
    md: "220px",
    lg: "250px",
    xl: "250px",
    xxl: "300px",
  },
  overflowY: "scroll",
};

export const topTitleStyle = {
  title: "News",
  width: {
    xxxs: "160px",
    xxs: "230px",
    xs: "260px",
    sm: "580px",
    md: "650px",
    lg: "600px",
    xxl: "650px",
  },
};

export const newsTitleFontStyle = {
  textAlign: "left",
  fontSize: {
    xxxs: "14px",
    xs: "16px",
    sm: "20px",
    md: "18px",
    lg: "18px",
    xxl: "20px",
  },
  marginTop: {
    xxxs: "40px",
    xs: "50px",
    sm: "40px",
    md: "70px",
    xxl: "90px",
  },
};

export const dividerStyle = {
  borderBottomWidth: 0.7,
  width: "100%",
  marginTop: {
    xxxs: "14px",
    sm: "30px",
    md: "50px",
    lg: "30px",
    xxl: "40px",
  },
  marginBottom: {
    xxxs: "30px",
    sm: "60px",
    md: "80px",
    lg: "58px",
    xxl: "65px",
  },
  borderColor: "#ccc",
};

export const contentsFontStyle = {
  textAlign: "left",
  fontSize: {
    xxxs: "12px",
    xs: "14px",
    sm: "16px",
    md: "18px",
    lg: "14px",
    xxl: "16px",
  },
  lineHeight: {
    xxxs: 2.5,
  },
};
