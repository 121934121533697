import React from "react";
import { Table, TableBody, TableCell, TableRow } from "@mui/material";
import { productData } from "../data/productNews";
import { Link } from "react-router-dom";
import {
  tableCellStyle,
  tableRowStyle,
  tableStyle,
} from "../style/allNewsStyle";

function EventNews() {
  return (
    <Table sx={tableStyle} aria-label="simple table" className="news-table">
      <TableBody>
        {productData.map((row) => (
          <TableRow key={row.name} sx={tableRowStyle}>
            <TableCell component="th" scope="row" sx={tableCellStyle}>
              {row.date}
            </TableCell>
            <TableCell sx={tableCellStyle}>
              <Link to={`/news/${row.id}`} style={{ color: "#000" }}>
                {row.name}
              </Link>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

export default EventNews;
