import React from "react";
import PropTypes from "prop-types";
import { Box, Divider, Typography } from "@mui/material";
import {
  containerStyle,
  titleDividerStyle,
  titleFontStyle,
} from "../style/productTitleStyle";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function ProductTitle() {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      className="product-title"
      sx={containerStyle}
    >
      <Box data-aos="fade-right" data-aos-easing="ease-in-sine">
        <Typography sx={titleFontStyle}>Product</Typography>
      </Box>
      <Box
        data-aos="fade-right"
        data-aos-easing="ease-in-sine"
        data-aos-delay="500"
        display="flex"
        alignItems="center"
      >
        <Divider textAlign="right" sx={titleDividerStyle} />
      </Box>
    </Box>
  );
}

export default ProductTitle;
