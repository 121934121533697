import React from "react";
import { Box } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import {
  bgStyle,
  bottomLogoStyle,
  containerStyle,
  middleLogoStyle,
  mobileBottomLogoStyle,
  mobileTopLogoStyle,
} from "../style/\broadingStyle";

function Roading() {
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1800,
        xxl: 2400,
      },
    },
  });
  return (
    <div className="roading">
      <ThemeProvider theme={theme}>
        <Box sx={containerStyle}>
          <Box
            data-aos="fade-zoom-in"
            data-aos-easing="ease-in-sine"
            data-aos-offset="0"
            component="img"
            zIndex="1200"
            sx={middleLogoStyle}
            src="https://wasei-golf-korea.com/images/bg45.png"
            alt="bg"
          />
          <Box
            data-aos="fade-zoom-in"
            data-aos-easing="ease-in-sine"
            data-aos-delay="1000"
            data-aos-offset="0"
            component="img"
            position="fixed"
            zIndex="1200"
            sx={bottomLogoStyle}
            src="https://wasei-golf-korea.com/images/logo6.png"
            alt="bg"
          />
          <Box
            data-aos="fade-zoom-in"
            data-aos-easing="ease-in-sine"
            data-aos-offset="0"
            component="img"
            position="fixed"
            zIndex="1200"
            sx={mobileTopLogoStyle}
            src="https://wasei-golf-korea.com/images/bg46.png"
            alt="bg"
          />
          <Box
            data-aos="fade-zoom-in"
            data-aos-easing="ease-in-sine"
            data-aos-delay="1000"
            data-aos-offset="0"
            component="img"
            position="fixed"
            zIndex="1200"
            sx={mobileBottomLogoStyle}
            src="https://wasei-golf-korea.com/images/bg47.png"
            alt="bg"
          />
          <Box
            data-aos="fade-zoom-in"
            data-aos-easing="ease-in-sine"
            data-aos-delay="3000"
            data-aos-offset="0"
            data-aos-anchor-placement="bottom-bottom"
            position="fixed"
            zIndex="1200"
            sx={bgStyle}
          />
        </Box>
      </ThemeProvider>
    </div>
  );
}

export default Roading;
