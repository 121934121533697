export const containerStyle = {
  height: {
    xxxs: "92vh",
    xxs: "120vh",
    xxss: "98vh",
    xs: "95vh",
    sm: "1400px",
    md: "1600px",
    lg: "850px",
    xl: "95vh",
    xxl: "95vh",
  },
};

export const titleContainerStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100vw",
};

export const titleStyle = {
  justifyContent: "center",
  alignItems: "center",
  paddingTop: {
    xxxs: "80px",
    xxs: "80px",
    xs: "80px",
    sm: "120px",
    md: "180px",
    lg: "170px",
    xxl: "200px",
  },
};

export const gridContainerStyle = {
  marginTop: { xxxs: "20px", xxs: "15px", lg: "30px" },
  paddingLeft: {
    xxxs: "7%",
    xxs: "6%",
    xs: "7%",
    lg: "5%",
    xl: "2.5%",
  },
  paddingRight: {
    xxxs: "7%",
    xxs: "6%",
    xs: "7%",
    lg: "5%",
    xl: "2.5%",
  },
};

export const productImageStyle = {
  height: {
    xxs: "220px",
    xs: "250px",
    sm: "480px",
    md: "522px",
    lg: "400px",
    xl: "548px",
    xxl: "718px",
  },
  width: "100%",
  objectFit: "cover",
};

export const nameDividerStyle = {
  marginBottom: {
    xxxs: "10px",
    xxs: "10px",
    xs: "14px",
    lg: "15px",
  },
  borderBottomWidth: 0.7,
  width: { xxxs: "40px", md: "50px", lg: "50px" },
  borderColor: "#000",
};

export const nameFontStyle = {
  textAlign: "left",
  paddingLeft: { xxxs: "5%", lg: "2%" },
  fontSize: {
    xxs: "20px",
    xs: "24px",
    md: "26px",
    lg: "26px",
  },
  marginTop: { lg: "20px" },
};
