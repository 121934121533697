export const containerStyle = {
  width: {
    xxxs: "85vw",
    xxs: "88vw",
    xs: "85vw",
    lg: "90vw",
    xl: "95vw",
  },
};

export const titleFontStyle = {
  fontSize: {
    xxxs: "22px",
    xs: "26px",
    sm: "28px",
    md: "32px",
    lg: "28px",
  },
};

export const titleDividerStyle = {
  borderBottomWidth: 0.7,
  marginRight: { md: "17%", lg: "40%" },
  width: {
    xxxs: `calc(95vw - 140px)`,
    xxs: `calc(95vw - 150px)`,
    xs: `calc(95vw - 170px)`,
    sm: `calc(95vw - 220px)`,
    md: `calc(95vw - 270px)`,
    lg: `calc(95vw - 240px)`,
    xl: `calc(95vw - 200px)`,
  },
};
